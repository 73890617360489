<template>
  <LoginLayout>
    <div class="login-item">
      <h1 class="login-headline">パスワードを変更してください</h1>
      <p class="description text-red"><span class="text-note">10文字以上で半角英数字および記号（/ \ ¥ を除く）を全て組み合わせたパスワードで入力して下さい</span></p>
      <div class="login-container">
        <form>
          <div class="form">
            <div class="form-row">
              <p class="form-label">新しいパスワード</p>
              <div class="form-content">
                <div class="form-password">
                  <input
                    v-trim
                    v-model="form.new"
                    :class="{ 'form-control form-password-input': true, 'is-error': errors.new }"
                    :type="pwdNewVisible ? 'text' : 'password'"
                    name="new"
                  />
                  <label class="form-check form-password-toggle">
                    <input class="form-check-input" type="checkbox" name="pwdNewVisible" v-model="pwdNewVisible" />
                    <i class="aikon form-password-icon"></i>
                  </label>
                </div>
              </div>
            </div>
            <div class="form-row">
              <p class="form-label">再入力してください</p>
              <div class="form-content">
                <div class="form-password">
                  <input
                    v-trim
                    v-model="form.confirm"
                    :class="{ 'form-control form-password-input': true, 'is-error': errors.confirm }"
                    :type="pwdConfirmVisible ? 'text' : 'password'"
                    name="confirm"
                  />
                  <label class="form-check form-password-toggle">
                    <input class="form-check-input" type="checkbox" name="pwdConfirmVisible" v-model="pwdConfirmVisible" />
                    <i class="aikon form-password-icon"></i>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <ActionButton class="btn btn-w100 btn-blue" :handle-submit="changePassword" button-text="登録する" />
        </form>
      </div>
    </div>
  </LoginLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, sameAs } from 'vuelidate/lib/validators';
import { isAlphanumSpecialWithMinLength } from '@/helpers/validators';
import password from '@/mixins/plugin/password';
import error from '@/mixins/plugin/error';
import { RouterConstants } from '@/constants/router';
import LoginLayout from '@/components/LoginLayout.vue';

export default {
  name: 'PasswordDefault',
  data() {
    return {
      pwdNewVisible: false,
      pwdConfirmVisible: false,
      form: {},
    };
  },
  props: {
    copyright: String,
  },
  components: {
    LoginLayout,
  },
  computed: {
    ...mapGetters({
      token: 'auth/token',
      passwordExpiresAt: 'auth/passwordExpiresAt',
      userRole: 'auth/userRole',
    })
  },
  validations() {
    const form = {
      new: { required, alphanumSpecialWithMinLength: isAlphanumSpecialWithMinLength(10) },
      confirm: { confirmPasswordRequired: required },
    }
    if (this.form.confirm) {
      form.confirm = { sameAsNewPassword: sameAs('new') }
    }
    return { form };
  },
  mixins: [error, password],
  methods: {
    changePassword: async function() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.getErrors(this.$v.form, 'setPassword');
      } else {
        const result = await this.$store.dispatch("auth/changePassword", {
          new: this.form?.new
        });
        if (result) this.checkAuthorization();
      }
    },
    async checkAuthorization() {
      await this.$store.dispatch("auth/getUserInfor");
      if (this.token && this.passwordExpiresAt) {
        if (this.userRole) {
          if(this.$permission.isGmoOrOffice()) {
            this.$router.push({ name: RouterConstants.DEFAULT_ROUTER_OF_MASTER_OR_CLIENT });
          }
          if(this.$permission.isStoreOrGroup()) {
            this.$router.push({ name: RouterConstants.DEFAULT_ROUTER_OF_SHOP_OR_GROUP });
          }
        }
      }
    }
  },
  created: function() {
    this.$parent.transitionName = '';
  },
};
</script>

